<template>
  <el-main>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="套餐名称：" prop="package_name">
        <el-input class="mr20" v-model="ruleForm.package_name" maxlength="15" placeholder="请输入内容"></el-input>
        <span class="tipsSpan">名称不超过15个字</span>
      </el-form-item>
      <el-form-item label="套餐主图：" prop="package_picture">
        <ReadyUploadSource
          @getSource="getSource"
          @changeOrder="list => (ruleForm.package_picture = list)"
          @removeThis="index => ruleForm.package_picture.splice(index, 1)"
          :isMany="true"
          :manyPath="ruleForm.package_picture"
          :isManyMax="10"
          :maxSize="1024 * 1024"
        ></ReadyUploadSource>
        <p class="tipsSpan">建议图片尺寸800*800px，大小不超过1M，最多可以上传10张图片，其中第一张为主图（有主图视频除外）</p>
      </el-form-item>
      <el-form-item label="套餐价格：" prop="package_price">
        <el-input type="number" placeholder="请输入内容" v-model="ruleForm.package_price">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="选择套餐商品：" prop="goods_data">
        <el-button size="small" class="mr20 mb20" @click="startChooseGoods" style="color: #409eff">+ 添加套餐商品</el-button>
        <el-table :data="ruleForm.goods_data" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="商品信息" align="center">
            <template v-slot="{ row }">
              <div class="goodsInfo">
                <el-image style="width: 40px; height: 40px" :src="row.picture"></el-image>
                <div>
                  <div class="goodsName">{{ row.goodsName }}</div>
                  <div class="price">￥{{ row.price }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="specTitle" label="参与规格" align="center"></el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="{ $index }">
              <el-button type="text" @click="deleteGoodsData($index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="运费设置：" class="item set-freight">
        <el-radio-group v-model="ruleForm.freight_type" @change="setfreightChange">
          <div class="set-unified-freight">
            <el-radio :label="0">统一运费</el-radio>
            <el-input type="number" :min="0" placeholder="请输入内容" @change="ruleForm.freight_type = 0" v-model="ruleForm.freight_price">
              <template slot="append">元</template>
            </el-input>
            <p style="font-size: 14px; margin-left: 15px" class="tipsSpan">填写“0”或不填则代表免费包邮</p>
          </div>
          <div class="set-template">
            <el-radio @change="ruleForm.freight_price = ''" :label="1">运费模板</el-radio>
            <el-select v-model="ruleForm.freight_id" @change="ruleForm.freight_type = 1" filterable placeholder="请选择">
              <el-option v-for="item in freightList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <router-link to="/set/freight" style="margin-left: 15px">
              <el-button type="text">没有模板，去添加模板</el-button>
            </router-link>
          </div>
          <div class="set-template default-freight">
            <el-radio :label="2">默认模板</el-radio>
            <p style="font-size: 14px" class="tipsSpan">无默认模板则默认为运费为0</p>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="附加赠送：" prop="is_gift_card_item">
        <el-switch v-model="ruleForm.is_gift_card_item" :active-value="1" :inactive-value="0"></el-switch>
        <div v-if="ruleForm.is_gift_card_item">
          <div v-for="(card, index) in ruleForm.card_item_data" :key="index" class="cardBox">
            <el-select class="mr20 w190" v-model="card.card_item_id" placeholder="请选择">
              <el-option v-for="item in cardList" :key="item.id" :label="item.card_item_name" :value="item.id"></el-option>
            </el-select>
            <el-input class="w190" min="1" type="number" placeholder="卡项张数" v-model="card.gift_sum">
              <template slot="append">张</template>
            </el-input>
            <i class="el-icon-close deleteCard" v-if="ruleForm.card_item_data.length > 1" @click="ruleForm.card_item_data.splice(index, 1)"></i>
          </div>
          <el-button type="text" @click="addCard">+添加卡项</el-button>
        </div>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-radio-group v-model="ruleForm.status">
          <el-radio :label="0">下架</el-radio>
          <el-radio :label="1">上架</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 选择商品弹框 -->
    <el-dialog title="选择商品" :visible.sync="chooseGoodsFlag" width="800px">
      <div class="goodsList">
        <el-form class="el-form-search" label-width="90px">
          <el-form-item label="搜索商品：">
            <el-input v-model="goodsForm.goods_name" size="small" placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item label="选择分类：">
            <el-cascader size="small" clearable v-model="goodsForm.classify_id" :options="goodsClass" @change="typeChange"></el-cascader>
          </el-form-item>
          <el-form-item label=" " label-width="20px">
            <el-button @click="getGoodsList(1)" type="primary" size="small">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="goodsList" style="width: 100%" :row-class-name="setRowClass">
          <el-table-column type="expand">
            <template v-slot="{ row }">
              <div class="spec_box" v-for="(item, index) in row.sku" :key="index">
                <div class="goodsInfo">
                  <el-image style="width: 40px; height: 40px" :src="item.picture"></el-image>
                  <div class="goodsName">{{ item.title }}</div>
                </div>

                <div class="price">￥{{ item.price }}</div>
                <div class="caozuo" @click="chooseThatGoods(row, item)">选择</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="商品信息" align="center">
            <template v-slot="{ row }">
              <div class="goodsInfo">
                <el-image style="width: 60px; height: 60px" :src="row.goods_picture"></el-image>
                <div class="goodsName">{{ row.goods_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单价" align="center">
            <template v-slot="{ row }">￥{{ row.goods_price }}</template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template v-slot="{ row }">
              <el-button v-if="!row.is_spec" type="text" @click="chooseThatGoods(row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="goodsForm.page" :pageNum="goodsForm.rows" @updatePageNum="updateData"></Paging>
      </div>
    </el-dialog>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Paging from '@/components/paging';
import _ from 'lodash';
import Preservation from '@/components/preservation';
export default {
  components: {
    ReadyUploadSource,
    Paging,
    Preservation,
  },
  data() {
    function checkPicture(rule, value, callback) {
      if (!value.length) {
        callback(new Error('请上传套餐图片'));
      } else {
        callback();
      }
    }
    function checkGoodsData(rule, value, callback) {
      if (!value.length) {
        callback(new Error('请选择要加入套餐的商品'));
      } else {
        callback();
      }
    }
    function checkCard(callback, that) {
      if (that.ruleForm.is_gift_card_item) {
        if (!that.ruleForm.card_item_data.length) {
          callback(new Error('请添加附加赠送的卡项'));
        } else {
          let flag = true;
          let error = '';
          for (let i = 0; i < that.ruleForm.card_item_data.length; i++) {
            let item = that.ruleForm.card_item_data[i];
            if (!item.card_item_id) {
              error = '请选择要赠送的卡片';
              flag = false;
              break;
            } else if (!item.gift_sum) {
              error = '请添加赠送的卡片数量';
              flag = false;
              break;
            }
          }
          if (!flag) {
            callback(new Error(error));
          } else {
            callback();
          }
        }
      }
      callback();
    }
    let that = this;
    return {
      update_id: 0,
      ruleForm: {
        type: 1,
        package_name: '',
        package_picture: [],
        package_price: '',
        goods_data: [],
        freight_type: 2,
        freight_price: '',
        freight_id: null,
        is_gift_card_item: 0,
        card_item_data: [{ card_item_id: null, gift_sum: '' }],
        status: 1,
      },
      rules: {
        package_name: [
          { required: true, message: '请输入套餐名称', trigger: 'blur' },
          { max: 15, message: '名称不超过15个字', trigger: 'blur' },
        ],
        package_picture: [{ required: true, validator: checkPicture, trigger: 'change' }],
        package_price: [{ required: true, message: '请输入套餐价格', trigger: 'blur' }],
        goods_data: [{ required: true, validator: checkGoodsData, trigger: 'change' }],
        is_gift_card_item: [{ validator: (rule, value, callback) => checkCard(callback, that), trigger: 'blur' }],
      },
      freightList: [],
      cardList: [],
      chooseGoodsFlag: false,
      goodsList: [],
      goodsForm: {
        page: 1,
        rows: 5,
        goods_name: '',
        classify_id: '',
      },
      total_number: 0,
    };
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getfreightList();
    this.getCardList();
    if (this.$route.query.id) {
      this.update_id = this.$route.query.id;
      this.getUpdateInfo();
    }
  },
  computed: {
    goodsClass() {
      let goodsTypeList = _.cloneDeep(this.$store.getters.goodsTypeList);
      goodsTypeList.unshift({
        value: 0,
        label: '全部',
      });
      return goodsTypeList;
    },
  },
  methods: {
    //获取运费模板
    getfreightList() {
      this.$axios.post(this.$api.set.freightList, { rows: 10000 }).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.freightList = list;
        }
      });
    },
    // 获取需要编辑的套餐信息 并整理数据
    getUpdateInfo() {
      this.$axios
        .post(this.$api.beauty.setMeal.setMealInfo, {
          id: this.update_id,
        })
        .then(res => {
          if (res.code === 0) {
            let obj = _.cloneDeep(res.result);
            obj.package_picture = JSON.parse(res.result.package_picture);
            obj.goods_data = [];
            delete obj.goods_datas;
            obj.card_item_data = obj.card_item_data ? JSON.parse(obj.card_item_data) : [{ card_item_id: null, gift_sum: '' }];
            if (obj.freight_type != 1) obj.freight_id = null;
            if (res.result.card_item_data) {
              obj.card_item_data.map(item => {
                item.card_item_id = Number(item.card_item_id);
                item.gift_sum = Number(item.gift_sum);
              });
            }
            res.result.goods_datas.map(item => {
              let data = {
                goodsName: item.goods_name,
                goods_id: item.id,
              };
              if (item.is_spec) {
                data.sku_id = item.sku[0].id;
                data.picture = item.sku[0].picture;
                data.price = item.sku[0].price;
                data.specTitle = item.sku[0].title;
              } else {
                data.sku_id = 0;
                data.picture = item.goods_picture;
                data.price = item.goods_price;
                data.specTitle = '--';
              }
              obj.goods_data.push(data);
            });
            this.ruleForm = obj;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 获取组件中选中的图片
    getSource(list) {
      list.map(item => {
        this.ruleForm.package_picture.push(item.path);
      });
    },
    getCardList() {
      this.$axios.post(this.$api.beauty.service.noPage).then(res => {
        this.cardList = res.result;
      });
    },
    // 开始选购商品
    startChooseGoods() {
      this.getGoodsList();
      this.chooseGoodsFlag = true;
    },
    updateData(val, status) {
      if (status == 0) {
        this.goodsForm.rows = val;
      } else {
        this.goodsForm.page = val;
      }
      this.getGoodsList();
    },
    getGoodsList(style) {
      if (style) this.goodsForm.page = 1;
      let obj = {
        page: this.goodsForm.page,
        rows: this.goodsForm.rows,
      };
      if (this.goodsForm.goods_name) {
        obj.goods_name = this.goodsForm.goods_name;
      }
      if (this.goodsForm.classify_id) {
        obj.classify_id = this.goodsForm.classify_id;
      }
      this.$axios.post(this.$api.beauty.setMeal.goodsList, obj).then(res => {
        if (res.code === 0) {
          this.goodsList = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    setRowClass({ row, index }) {
      return row.is_spec ? '' : 'no_spec';
    },
    chooseThatGoods(data, spec) {
      let obj = {
        goodsName: data.goods_name,
        goods_id: data.id,
      };
      let str = '';
      if (spec) {
        obj.sku_id = spec.id;
        obj.picture = spec.picture;
        obj.price = spec.price;
        obj.specTitle = spec.title;
        str = '已添加过此规格数据';
      } else {
        obj.sku_id = 0;
        obj.picture = data.goods_picture;
        obj.price = data.goods_price;
        obj.specTitle = '--';
        str = '已添加过该商品';
      }
      let is_has = this.ruleForm.goods_data.find(item => JSON.stringify(item) === JSON.stringify(obj));
      if (is_has) {
        this.$message({
          message: str,
          type: 'warning',
          customClass: 'mzindex',
        });
      } else {
        this.ruleForm.goods_data.push(obj);
        this.chooseGoodsFlag = false;
      }
    },
    deleteGoodsData(index) {
      this.ruleForm.goods_data.splice(index, 1);
    },
    preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          obj.goods_data = [];
          if (!obj.is_gift_card_item) {
            delete obj.card_item_data;
          }
          this.ruleForm.goods_data.map(item => {
            let goods = {
              goods_id: item.goods_id,
              sku_id: item.sku_id,
              goods_price: item.price,
            };
            obj.goods_data.push(goods);
          });
          let url = this.update_id ? this.$api.beauty.setMeal.editSetMeal : this.$api.beauty.setMeal.addSetMeal;
          this.$axios.post(url, obj).then(res => {
            if (res.code === 0) {
              let str = this.update_id ? '编辑成功' : '添加成功';
              this.$message.success(str);
              this.$router.push('/extension/setMealList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    // 点击添加卡项
    addCard() {
      this.ruleForm.card_item_data.push({ card_item_id: null, gift_sum: '' });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  padding-bottom: 70px;
  .el-input,
  .el-select {
    width: 400px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .tipsSpan {
    color: #999999;
    white-space: nowrap;
    line-height: 25px;
  }
  .w190 {
    width: 190px;
  }
  .goodsList {
    .el-input,
    .el-select {
      width: 200px;
    }
  }
}
.el-table /deep/ .no_spec > td > .cell > .el-table__expand-icon {
  display: none;
  & > .el-icon {
    display: none;
  }
}
.goodsInfo {
  display: flex;
  align-items: center;
  text-align: left;
  .goodsName {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-left: 15px;
  }
  .price {
    margin-left: 15px;
    color: #999;
  }
}
.spec_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 30px;
  .goodsName {
    width: 120px;
  }
  & > div {
    padding: 0 10px;
  }
  .price,
  .caozuo {
    flex: 1;
    text-align: center;
  }
  .caozuo {
    color: #409eff;
    cursor: pointer;
  }
}

.el-table {
  width: 700px;
}
.cardBox {
  display: flex;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.deleteCard {
  margin-left: 10px;
  font-size: 24px;
  cursor: pointer;
}
.set-freight {
  align-items: flex-start;
  display: inline-block;
  span {
    padding-top: 5px;
  }
  .set-unified-freight {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .set-template {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
